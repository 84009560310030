
//销售管理页面 
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { defineComponent ,ref ,reactive, toRefs, onMounted, onBeforeMount} from 'vue';
import { FileTextOutlined } from '@ant-design/icons-vue';
import  withdrawDialog  from '@/components/withdrawManage/dialog.vue';
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue';
import { getList } from '@/api/index';
import { getCashList , withApplication} from '@/api/saleManage/withdraw';
import { message } from 'ant-design-vue';
export default defineComponent({
    name:'withdrawManage',
    components:{
        FileTextOutlined,
        withdrawDialog,
        breadcrumb
    },

    setup(){
        const brea = ref<object []>([
            {title:'首页',path:'#/'},
            {title:'销售管理',path:'/index/saleManage'},
            {title:'提现管理',path:'#/'},
        ]) 
        const day = ref<string | number>('')
        const dialog = ref('');
        // 提现申请modal
        const withApply = reactive({
            visible:false,
            dataSource:{}
        })

        // 提现详单&提现记录 下拉
        const cashSelect =  ref('0');
        const recordSelect =  ref('');
        
        // 提现详单page
        const cashPage = reactive({
            page:1,
            perpage:10,
            total:0
        })
        // 提现记录page
        const dataPage = reactive({
            page:1,
            perpage:10,
            total:0
        })
        
        const url = ref<string>('/agent-api/api/cash_record_list')

        //提现详单 columns
        const columns2 = [
            {
                title: '名称',
                dataIndex: 'cash_name',
                key: 'cash_name',
            },
            {
                title: '提现金额',
                dataIndex: 'amount_sum',
                key: 'amount_sum',
                width:100
            },
            {
                title: '二维码',
                children: [
                    {
                        title: '微信',
                        dataIndex: 'er_wx',
                        key: 'er_wx',
                    },
                    {
                        title: '支付宝',
                        dataIndex: 'er_zfb',
                        key: 'er_zfb',
                    },
                    {
                        title: '安卓',
                        dataIndex: 'er_az',
                        key: 'er_az',
                    },
                    {
                        title: '苹果',
                        dataIndex: 'er_pg',
                        key: 'er_pg',
                    },
                ]
            },
            {
                title: '自然流量',
                width: 80,
                children:[
                    {
                        title: '安卓',
                        dataIndex: 'zr_az',
                        key: 'zr_az',
                        width: 200,
                    },
                    {
                        title: '苹果',
                        dataIndex: 'zr_pg',
                        key: 'zr_pg',
                    },
                ]
            },
        ];
        // 提现详单表格数据
        const cashData = reactive({
            dataSource:[],
            bank_info:{
                province:'',
                corporate_name:'',
                bank_card_number:'',
                bank_card_name:'',
                bank_card_subname:''
            },
            apply:{
                
            }
        })
         // 提现记录 columns
        const columns = [
            { title: '时间段', dataIndex: 'time', key: 'time', ellipsis: true,},
            { title: '提现团队', width: 100, dataIndex: 'province', key: 'province'},
            { title: '提现人', dataIndex: 'user_name', key: 'user_name'},
            { title: '总金额', dataIndex: 'amount', key: 'amount'},
            { title: '状态', dataIndex: 'status', key: 'status'},
            { title: '申请时间', dataIndex: 'create_time', key: 'create_time'},
            { title: '操作', dataIndex: 'operation', key: 'operation'},
        ];
        // 提现记录表格
        const data = ref([]);

        onBeforeMount(()=>{
            getCashTableList()
            day.value = new Date().getDate()
            query()
           
        })
        // 提现记录 查询
        const query = () =>{
            
            getList('get',url.value,{page:dataPage.page,status:recordSelect.value,perpage:dataPage.perpage})
            .then((res:any)=>{
                if(res.code == 200){
                    data.value = res.data.models;
                    dataPage.total = res.count;
                }
            })
        }
        const detail = (val:any)=>{
            (dialog.value as any).showModal(val)
        }
        // 提现详单list
        const getCashTableList = ():void =>{
            getCashList({page:cashPage.page,perpage:cashPage.perpage}).then((res:any)=>{
                if(res.code == 200){
                    (cashData.dataSource as any).push(res.data);
                    cashData.apply = res.data;
                    for (const key in res.data.bank_info) {
                        (cashData.bank_info as any)[key] = res.data.bank_info[key]
                        console.log(key,);
                        
                    }
                    (cashData.apply as any).amount_sum = (cashData.apply as any).amount_sum.replace('￥',''); 
                }
            })
        }

        // 提现记录 分页变化
        const tableChange = (page:any):void =>{
            dataPage.page = page.current;
            dataPage.perpage = page.pageSize;
            query()
        }
        // 确认提现
        const handleOk = ():void =>{
            const data = {
                amount:(cashData.apply as any).amount_sum,
                start_time:(cashData.apply as any).start_time,
                end_time:(cashData.apply as any).end_time,
            }
            withApplication(data).then((res:any)=>{
                if(res.code == 200){
                    message.success('申请成功');
                    withApply.visible = false;
                    getCashTableList()
                }
            })
        }

        return {
            dialog,
            locale,
            cashSelect,
            recordSelect,
            columns,
            data,
            brea,
            day,
            columns2,
            dataPage,
            withApply,
            ...toRefs(cashData),
            query,
            detail,
            tableChange,
            handleOk
        }
    }
})
